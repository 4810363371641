import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const EmailSentMessage = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  line-height: 1.63;
`;

export const EmailSentMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 100px;
  text-align: center;
`;
