import React, { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  FormError,
  FormHeaderLabel,
  FormHeaderWrapper,
  FormInput,
  FormLabel,
  FormSection,
  FormSubmitButton,
  FormWrapper,
  InputWrapper,
  LoginFormButtonsContainer,
  PageContainer,
  PageCR,
  PageFooter,
  PageLogo
} from '../../components/Shared/Forms/Forms';
import { Container } from '../../components/Shared/Shared';
import { isEmail } from '../../utils/validators';
import { SendBusUserForgetPasswordEmail } from '../../queries';
import EmailSentView from './EmailSentView';
import useIcons from '../../hooks/useIcons';

const AccountRecoveryForm = ({ onSubmit, emailSent, loading, error }: any) => {
  const icons = useIcons();
  const { control, handleSubmit, errors } = useForm();

  const sendEmail = handleSubmit(onSubmit);
  const email = useMemo(() => {
    const email = control.getValues()?.email;
    if (email) {
      return email;
    }
    return '';
  }, [emailSent]);

  if (emailSent) {
    return <EmailSentView email={email} />;
  }

  return (
    <PageContainer>
      <PageLogo alt={'Collar Logo'} image={icons.logoLarge.childImageSharp.gatsbyImageData} />
      <FormWrapper display={'flex'} flexDirection={'column'} maxWidth={480} width={'480px'}>
        <FormSection alignItems={'center'}>
          <FormHeaderWrapper>
            <FormHeaderLabel margin={'32px 0 32px 0'}>Reset Your Password</FormHeaderLabel>
          </FormHeaderWrapper>
          <Container>
            <InputWrapper>
              <FormLabel error={errors.email}>Email</FormLabel>
              <Controller
                as={<FormInput error={errors.email} type={'email'} />}
                control={control}
                name={'email'}
                defaultValue={''}
                rules={{
                  required: true,
                  pattern: { value: isEmail, message: 'Invalid Email Address' }
                }}
              />
              {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
            </InputWrapper>
          </Container>
          <LoginFormButtonsContainer>
            <FormSubmitButton onClick={sendEmail} error={!!error} loading={loading}>
              Reset Password
            </FormSubmitButton>
          </LoginFormButtonsContainer>
        </FormSection>
      </FormWrapper>
      <PageFooter>
        <PageCR>Copyright © {new Date().getFullYear()} Collar. All Rights Reserved</PageCR>
      </PageFooter>
    </PageContainer>
  );
};

const AccountRecoveryView = () => {
  const [sendEmail, { data: { sendBusUserForgetPasswordEmail: emailSent } = {}, loading, error }] = useMutation(SendBusUserForgetPasswordEmail, { fetchPolicy: 'no-cache' });

  const onSubmit = (variables: any) => {
    sendEmail({ variables, fetchPolicy: 'no-cache' });
  };

  useEffect(() => {
    if (emailSent) {
      window?.history?.replaceState?.({}, 'Email Sent', '/email-sent');
    }
  }, [emailSent]);

  return <AccountRecoveryForm {...{ onSubmit, emailSent, loading, error }} />;
};

export default AccountRecoveryView;
