import React from 'react';
import { FormHeaderLabel, FormHeaderWrapper, FormSection, FormWrapper, PageContainer, PageCR, PageFooter, PageLogo } from '../../components/Shared/Forms/Forms';
import useIcons from '../../hooks/useIcons';
import { EmailSentMessage, EmailSentMessageWrapper } from './styled';

const EmailSentView = ({ email }: { email: string }) => {
  const icons = useIcons();

  return (
    <PageContainer>
      <PageLogo alt={'Collar Logo'} image={icons.logoLarge.childImageSharp.gatsbyImageData} />
      <FormWrapper display={'flex'} flexDirection={'column'} maxWidth={480} width={'480px'}>
        <FormSection alignItems={'center'}>
          <FormHeaderWrapper>
            <FormHeaderLabel margin={'32px 0 32px 0'}>Reset Your Password</FormHeaderLabel>
          </FormHeaderWrapper>
          <EmailSentMessageWrapper>
            <EmailSentMessage>An email with a reset link has been sent to {email}</EmailSentMessage>
          </EmailSentMessageWrapper>
        </FormSection>
      </FormWrapper>
      <PageFooter>
        <PageCR>Copyright © {new Date().getFullYear()} Collar. All Rights Reserved</PageCR>
      </PageFooter>
    </PageContainer>
  );
};

export default EmailSentView;
