import React from 'react';
import { ModalPageContainer } from '../../styled';
import AccountRecoveryView from '../../views/AccountRecovery/AccountRecoveryView';

const AccountRecoveryPage = () => {
  return (
    <ModalPageContainer>
      <AccountRecoveryView />
    </ModalPageContainer>
  );
};

export default AccountRecoveryPage;
